<template>
	<div class="find-email mx-auto">
		<v-card class="d-flex align-start flex-column mx-auto" elevation="0">
			<h1 class="heading">{{ $t('findEmail.title') }}</h1>
			<p
				class="text-sm"
				v-html="$t('findEmail.subTitle')"
				v-if="!foundEmail"
			></p>
			<div class="form">
				<v-stepper v-model="activeStep" elevation="0" class="pa-0">
					<v-stepper-items>
						<v-stepper-content step="1" class="pa-1">
							<validation-observer ref="observer" v-slot="{ valid }">
								<form @submit.prevent="verification" ref="form">
									<validation-provider
										v-slot="{ errors }"
										name="phone"
										rules="required"
									>
										<v-text-field
											v-model="findEmailForm.phoneNumber"
											:error-messages="errors"
											:label="$t('findEmail.findEmailForm.phoneNumber')"
											:placeholder="
												$t('findEmail.findEmailForm.phoneNumberPlaceholder')
											"
											@keypress="allowOnlyNumber($event)"
											required
										/>
									</validation-provider>
									<validation-provider
										v-slot="{ errors }"
										name="name"
										rules="required"
									>
										<v-text-field
											v-model="findEmailForm.name"
											:error-messages="errors"
											:label="$t('findEmail.findEmailForm.name')"
											required
										/>
									</validation-provider>
									<v-btn
										class="button full-width"
										type="submit"
										width="100%"
										color="primary"
										large
										:disabled="!valid"
										:loading="verificationLoading"
									>
										{{ $t('findEmail.findEmailForm.submit') }}
									</v-btn>
								</form>
							</validation-observer>
						</v-stepper-content>
						<v-stepper-content step="2" class="pa-1">
							<p class="mb-2">{{ $t('findEmail.findEmailForm.foundMsg') }}</p>
							<span class="text-sm secondary--text">이메일 주소</span>
							<h1 class="mb-3">{{ foundEmail }}</h1>
							<span class="text-sm secondary--text">유저명</span>
							<h1 class="mb-5">{{ foundUid }}</h1>
							<v-btn
								class="button full-width"
								type="submit"
								width="100%"
								color="primary"
								large
								:to="{ name: 'main' }"
							>
								{{ $t('findEmail.findEmailForm.routeToMain') }}
							</v-btn>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</div>
		</v-card>
	</div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'

import store from '@/store'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'

export default {
	components: {},
	setup() {
		const activeStep = ref(1)
		const verificationLoading = ref(false)
		const foundEmail = ref('')
		const foundUid = ref('')
		const expirationTimeLeft = ref('')
		const findEmailForm = reactive({
			name: '',
			phoneNumber: '',
		})

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const isMobile = () => {
			if (
				/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
					navigator.userAgent,
				)
			) {
				return true
			}
		}

		const getUserCertification = async imp_uid => {
			try {
				const data = await AuthService.findEmailByCi({
					iamportUid: imp_uid,
				})

				foundEmail.value = data.email
				foundUid.value = data.uid

				activeStep.value = 2
			} catch (e) {
				warningSwal('인증정보를 불러오는데 문제가 발생되었습니다')
			}
		}

		const callback = async response => {
			/* 3. 콜백 함수 정의하기 */
			const { success, imp_uid, error_msg } = response

			if (success) {
				successSwal('본인인증이 완료되었습니다')
				getUserCertification(imp_uid)
			} else {
				verificationLoading.value = false
				warningSwal(error_msg)
			}
		}

		const verification = async () => {
			verificationLoading.value = true
			try {
				/* 1. 가맹점 식별하기 */
				const { IMP } = window
				IMP.init(process.env.VUE_APP_MERCHANT_ID_CODE)

				/* 2. 본인인증 데이터 정의하기 */
				const data = {
					merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
					name: findEmailForm.name,
					phone: findEmailForm.phoneNumber,
					company: 'https://itmarketer.org/',
					popup: isMobile() ? true : false,
				}

				/* 4. 본인인증 창 호출하기 */
				IMP.certification(data, callback)
			} catch (e) {
				console.log(e)
			}
		}

		store.dispatch('app/setIsLogoWhite', false)

		return {
			activeStep,
			verificationLoading,
			findEmailForm,
			foundEmail,
			foundUid,
			expirationTimeLeft,

			verification,
			allowOnlyNumber,
		}
	},
}
</script>
<style lang="scss" scoped>
.find-email {
	height: 100vh;
	margin-top: 10em;
	padding-top: 36px;
	padding-left: 20px;
	padding-right: 20px;

	.v-card {
		padding-top: 44px;
		padding-left: 34px;
		padding-right: 34px;
		padding-bottom: 34px;
		width: 440px;
		max-width: 440px;
		border: 1px solid #e4e5ed;
		width: 100%;

		::v-deep {
			.v-stepper__wrapper {
				overflow: visible;
			}
		}

		.form {
			width: 100%;
		}

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		@media screen and (max-width: 480px) {
			width: 100%;
		}

		h1.heading {
			font-size: 30px;
			font-weight: normal;
			color: #303441;
			line-height: normal;
			margin-bottom: 20px;
			margin-top: 0;

			@media screen and (max-width: 768px) {
				font-size: 20px;
			}
		}
	}
}
</style>
